<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <strong>Update User</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CForm @keyup.enter="validator();submit()">
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.username.val" :description="form.username.inv" label="E-mail" />
                                </CCol>
                                <CCol sm="3">
                                    <CInput v-model="form.password.val" :description="form.password.inv" type="password" label="Password" />
                                </CCol>
                                <CCol sm="3">
                                    <CInput v-model="form.first_name.val" :description="form.first_name.inv" label="First Name" />
                                </CCol>
                                <CCol sm="3">
                                    <CInput v-model="form.last_name.val" :description="form.last_name.inv" label="Last Name" />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.sex.val"
                                        :description="form.sex.inv"
                                        label="Sex"
                                        :options="[
                                            {value: '1', label:'Male'},
                                            {value: '2', label:'Female'},
                                            {value: '3', label:'Other'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.sex.val=$event.target.value"
                                    />
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.is_active.val"
                                        :description="form.is_active.inv"
                                        label="State"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.is_active.val=$event.target.value"
                                    />
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.is_guest.val"
                                        :description="form.is_guest.inv"
                                        label="Guest User"
                                        :options="[
                                            {value: 'true', label:'Yes'},
                                            {value: 'false', label:'No'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.is_guest.val=$event.target.value"
                                    />
                                </CCol>
                            </CRow>
                            <br>
                        </CForm>
                        <br>
                        <div class="form-group form-actions">
                            <CButton @click="validator();submit()" color="primary" class="mr-2"> Update</CButton>
                            <CButton color="dark" @click="go_back">Back</CButton>
                        </div>    
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                username: {val: '', inv: '', v: { req: true, max: 1024, spec: 'email' }},
                password: {val: '', inv: '', v: { max: 1024, spec: 'password' }},
                first_name: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
                last_name: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
                sex: {val: '', inv: '', v: { req: true, max: 1024 }},
                is_active: {val: '', inv: '', v: { req: true, max: 1024 }},
                is_guest: {val: '', inv: '', v: { req: true, max: 1024 }},
			},
            disable: false,
        }
    },
    mounted(){
        this.get();
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'min':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length < this.form[key].v[k]) {
                                    this.form[key].inv = `Must be at least ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'spec':
                            switch(this.form[key].v[k]){
                                case 'email':
                                    if (this.form[key].val != '' && this.form[key].inv == '') {
                                        const re_email = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                                        if (!re_email.test(String(this.form[key].val).toLowerCase())) {
                                            this.form[key].inv = 'E-mail is not valid. ';
                                            this.disable = true;
                                        }
                                    }
                                    break;
                                case 'password':
                                    if(this.form[key].val != '' && this.form[key].inv == ''){
                                        const re_password = new RegExp(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/);
                                        if (!re_password.test(String(this.form[key].val))) {
                                            this.form[key].inv = 'Must be at least 8 characters and contain numbers, uppercase and lowercase letters.';
                                            this.disable = true;
                                        }
                                    }
                                    break;
                            }
                    }
                }
            }
        },
        get(){
           this.axios.get('system/users/'
                + '?id=' + this.$route.params.id)
                .then(res => {
                    for(const key in res.data[0]){
                        for(const k in this.form){
                            if(key == k){
                                if(key == 'is_active' || key == 'is_guest' || key == 'sex'){
                                   this.form[k].val = `${res.data[0][key]}`; 
                                } else{
                                    this.form[k].val = res.data[0][key];
                                }
                            }
                        }
                    }
                }) 
        },
        submit(){
            if(this.disable == false){
                const data = new FormData();
                data.set('ids', JSON.stringify([parseInt(this.$route.params.id)]));
                for (const key in this.form){
                    if(key != 'username'){
                        data.set(key, this.form[key].val);
                    }
                }
                this.axios.put('system/users/', data)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Updated Successfully.'
                        });
                        this.$router.push('/users');
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: 'Error! please try again.'
                        });
                    })
            }
        },
        go_back(){
            this.$router.go(-1);
        }
    }
}
</script>
